import React, { useEffect, useState } from 'react';
import { Button, Box, Grid, CircularProgress } from '@mui/material';
import { req } from './ServerUtils';

const MyTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    req('/user_info').then(uinfo => {
      setUserInfo(uinfo);
      if (!uinfo) {
        window.location.href = '/login';
      }
    }).catch(e => {
      window.location.href = '/login';
    })
    req('/designer/html_templates?page=1').then(templates => setTemplates(templates || []));
  }, []);

  if (!userInfo) {
    return <div >
      <CircularProgress />
    </div>;
  }

  return (
    <Box p={3}>
      <div style={{
        marginBottom: 20,
      }}>
        Hello {userInfo?.email}, these are your currently active templates:
      </div>
      <div className="row">
        {templates.map(template => (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 20,
              border: "1px solid black",
              padding: 20,
              borderRadius: 10,
            }}>
              <div>
                {template.id}
              </div>
              <div>
                <img src={template.inspiration} alt="Template Preview" style={{ 
                  width: 200,
                  height: 300,
                  objectFit: 'contain',
                  cursor: "pointer"
                }} onClick={(e)=> {
                    e.preventDefault();
                    window.open('/template/' + template.id, '_blank');
                }}/>
              </div>
            </div>
         ))}
      </div>
    </Box>
  );
};

export default MyTemplates;
