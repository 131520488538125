import { MenuItem, Select } from "@mui/material";

const emails = ["maninder@makemybrand.ai", "jaydip@makemybrand.ai", "admin@makemybrand.ai"];

export function SetDesignerWidget({designer, setDesigner}) {
    return (
        <div>
            <Select fullWidth size="small" value={designer} onChange={(e) => setDesigner(e.target.value)}>
                {emails.map((email, i) => (
                    <MenuItem key={i} value={email}>{email}</MenuItem>
                ))}
            </Select>
        </div>
    )
}