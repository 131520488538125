import React, { useEffect, useState } from 'react';
import { Button, Box, Grid } from '@mui/material';
import { req } from './ServerUtils';

const ApproveTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    req('/admin/html_templates?page=1').then(data => setTemplates(data.templates || []));
  }, []);

  function loadMore() {
    req(`/admin/html_templates?page=${page + 1}`).then(data => {
      setTemplates([...templates, ...data.templates]);
      setPage(page + 1);
    });
    
  }

  const handleApproval = (templateId, approved) => {
    req('/admin/approve', 'POST', JSON.stringify({ template_id: templateId, is_approved: approved }))
      .then(response => {
        setTemplates(templates.map(template => {
          if (template.id === templateId) {
            template.approved = approved;
          }
          return template;
        }));
    });
  };

  return (
    <Box p={3}>
      <div className="row">
        {templates.map(template => (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 20,
              border: "1px solid black",
              padding: 20,
              borderRadius: 10,
            }}>
              <div>
                {template.id}
              </div>
              <div>
                <img src={template.preview} alt="Template Preview" style={{ 
                  width: 100,
                  height: 200,
                  objectFit: 'cover',
                  cursor: "pointer"
                }} 
                onClick={() => window.open('/template/' + template.id, '_blank')}
                />
              </div>
              <Button
                variant="outlined"
                style={{ textTransform: 'none', color: template.approved ? 'red' : 'limegreen', borderColor: template.approved ? 'red' : 'limegreen' }}
                onClick={() => handleApproval(template.id, !template.approved)}
                >
                {template.approved ? "Unapprove" : "Approve"}
              </Button>
            </div>
         ))}
      </div>
      <div style={{
        textAlign: 'center',
        marginTop: 20,
      }}>
        <Button variant="outlined" color="primary" onClick={loadMore}>Load More</Button>
      </div>
    </Box>
  );
};

export default ApproveTemplates;
