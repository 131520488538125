import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { req, reqPoll } from "./ServerUtils";
import { CircularProgress } from "@mui/material";

const ZipUpload = ({}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }
    setError(null);

    const formData = new FormData();
    formData.append("zip_file", file);
    setLoading(true);

    reqPoll('/upload_template_zip', 'POST', formData, false)
        .then((res) => {
            console.log(res);
            window.open('/template/' + res.template_id, '_blank');
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
            setError(error.message || JSON.stringify(error));
            setLoading(false);
        });
  };

  return (
    <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "50px",
    }}>
        
        {loading ? <div>
            <div>Please wait. The zip file is being validated...</div>
            <div><CircularProgress /></div>
        </div> : (
            <div>
                <div>Upload the template zip file here:</div>
                <div
                    style={{
                        marginTop: 30,
                        width: "300px",
                        height: "200px",
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    >
                    {file ? (
                        <div>
                        <p>{file.name}</p>
                        <button onClick={handleUpload}>Upload</button>
                        </div>
                    ) : (
                        <p>Drag and drop your zip file here</p>
                    )}
                </div>
                {error && (<div style={{
                    color: "white",
                    backgroundColor: "red",
                    padding: "10px",
                    borderRadius: "5px",
                    marginTop: "10px",
                }}>
                    {error}
                </div>)}
            </div>
    )}
    </div>
  );
};

export default ZipUpload;
