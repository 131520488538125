import React, { useState } from "react";
import { req } from "./ServerUtils";
import { Button, IconButton } from "@mui/material";
import { ContentCopy, Delete } from "@mui/icons-material";


const ImagesUploader = ({templateId, replaceImageUrls, templateImages, setTemplateImages}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);
  

  const handleFiles = (files) => {
    const uploadPromises = Array.from(files).map((file, index) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("width", img.width);
            formData.append("height", img.height);
            formData.append("template_id", templateId);

            req("/admin/upload-template-image", "POST", formData, false)
              .then((res) => {
                if (res.error) {
                  reject({
                    error: res.error,
                    filename: file.name,
                  });
                } else {
                  resolve({
                    src: res.src,
                    filename: file.name
                  });
                  console.log(res.src)
                }
              })
              .catch((e) => reject(e));
          };
          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      })
        .then((res) => {
          setUploadProgress((prev) => {
            const updated = [...prev];
            updated[index] = res
            return updated;
          });
        })
        .catch(() => {
          setUploadProgress((prev) => {
            const updated = [...prev];
            updated[index] = {
              filename: file.name,
              error: "Failed to upload",
            }
            return updated;
          });
        });
    });

    setUploading(true);

    Promise.all(uploadPromises)
      .finally(() => {
        setUploading(false);
        setTimeout(() => {
          setUploadProgress([]);
        }, 5000);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*";
    input.onchange = (e) => {
      if (e.target.files) {
        handleFiles(e.target.files);
      }
    };
    input.click();
  };

  function handleCopy(text) {
    navigator.clipboard.writeText(text);
  }

  function handleDelete(imageUrl) {
    req("/admin/delete-template-image", "POST", JSON.stringify({ 
      template_id: templateId,
      image_url: imageUrl
    }))
      .then((res) => {
        if (res.error) {
          console.error(res.error);
        } else {
          setTemplateImages((prev) => {
            const updated = [...prev];
            updated.splice(updated.indexOf(imageUrl), 1);
            return updated;
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      // border: "1px solid #ccc",
      borderRadius: "10px",
      padding: 5,
      gap: 10,
      textAlign: "center",
    }}>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={handleClick}
        style={{
        }}
      >
        {uploading ? (
          <p>Uploading...</p>
        ) : (
          <Button variant="outlined" color="secondary">Upload Images</Button>
        )}
      </div>

      <div style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "row",
        gap: 10,
        flexWrap: "wrap",
      }}>
        {templateImages.map((imageUrl, index) => (
          <div key={index} style={{ 
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,

            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            width: "fit-content",
            padding: 5,
            cursor: "pointer",
          }}>

            <img src={`${imageUrl}`} alt={imageUrl} style={{ 
              width: 50, 
              height: 50, 
              objectFit: "cover" 
            }} onClick={() => handleCopy(imageUrl)}/>

            <IconButton onClick={() => handleDelete(imageUrl)} size="small" style={{ color: "rgb(212 118 118)" }}>
              <Delete fontSize="small" />
            </IconButton>
          </div>
        ))}
      </div>
      <div style={{

      }}>
        <Button onClick={replaceImageUrls} fullWidth variant="outlined" color="secondary">Replace Local Images in HTML</Button>
      </div>
      <div>
        {uploadProgress && Object.keys(uploadProgress).map((key, i) => {
          return (
            <div key={i} style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: '10px',
              marginBottom: '10px',
              padding: '10px',
            }}>
              {uploadProgress[key].src ? (
                <>
                  <div>{uploadProgress[key].filename}: </div>
                  <div>Success!</div>
                </>
              ) : (
                <>
                  <div>{uploadProgress[key].filename}: </div>
                  <div>Failed.</div>
                </>
              )}
          </div>
        )})}
      </div>
    </div>
    
  );
};

export default ImagesUploader;
