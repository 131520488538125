import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { req } from "./ServerUtils";

export function UserConsole({}) {
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState(null);

    function setAsDesigner(isDesigner) {
        req("/admin/set_is_designer", "POST", JSON.stringify({email, is_designer: isDesigner}))
    }

    function fetchProfile() {
        req("/admin/get_profile", "POST", JSON.stringify({email})).then((res) => {
            setProfile(res)
        })
    }

    return (
        <div style={{
            padding: 40,
        }}>
            <div style={{
                
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: 20
            }}>
                <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
                <Button variant="outlined" onClick={() => setAsDesigner(true)}>Add Designer Perm</Button>
                <Button variant="outlined" onClick={() => setAsDesigner(false)}>Delete Designer Perm</Button>

                <Button variant="outlined" onClick={fetchProfile}>See Profile</Button>
            </div>
            <div>
                {profile && <pre>
                    {JSON.stringify(profile, null, 4)}
                    </pre>}
            </div>
        </div>

    )
}