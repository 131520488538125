import React, { useState } from "react";
import { req } from "./ServerUtils";
import { Button, IconButton } from "@mui/material";
import { ContentCopy, Delete } from "@mui/icons-material";

const InspirationUploader = ({templateId, setTemplateInspiration}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const handleFile = (file) => {
    setUploadProgress(null);
    const uploadPromise = new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("width", img.width);
            formData.append("height", img.height);
            formData.append("template_id", templateId);

            req("/admin/upload-inspiration", "POST", formData, false)
              .then((res) => {
                if (res.error) {
                  reject({
                    error: res.error,
                    filename: file.name,
                  });
                } else {
                  resolve({
                    src: res.src,
                    filename: file.name
                  });
                  setTemplateInspiration(res.src);
                }
              })
              .catch((e) => reject(e));
          };
          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      })
        .then((res) => {
            console.log(res)
            setUploadProgress({
                filename: file.name,
                success: true,
            });
        })
        .catch((e) => {
            console.error(e);
            setUploadProgress({
              filename: file.name,
              error: true,
            });
        });

    setUploading(true);

    uploadPromise
      .finally(() => {
        setUploading(false);
        setTimeout(() => {
          setUploadProgress({});
        }, 5000);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*";
    input.onchange = (e) => {
      if (e.target.files) {
        handleFile(e.target.files[0]);
      }
    };
    input.click();
  };

  return (
    <div style={{

      border: "2px solid #ccc",
      borderRadius: "10px",
      padding: "20px",
      textAlign: "center",
    }}>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={handleClick}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {uploading ? (
          <p>Uploading file...</p>
        ) : (
          <p>Drag and drop the inspiration image here</p>
        )}
      </div>

      <div>
        {uploadProgress && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: '10px',
              marginBottom: '10px',
              padding: '10px',
            }}>
              {uploadProgress.success ? (
                <>
                  <div>{uploadProgress.filename}: </div>
                  <div>Success!</div>
                </>
              ) : (
                <>
                  <div>{uploadProgress.filename}: </div>
                  <div>Failed.</div>
                </>
              )}
          </div>
        )}
      </div>
    </div>
    
  );
};

export default InspirationUploader;
