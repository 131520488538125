import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import TemplateCreator from './TemplateCreator';
import MyTemplates from './MyTemplates';
import { UserConsole } from './UserConsole';
import { Designs } from './Designs';
import ApproveTemplates from './ApproveTemplates';

export function Admin() {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div>
            <Tabs value={activeTab} onChange={handleChange}>
                <Tab label="Create Template" />
                <Tab label="Approve Templates" />
                <Tab label="Users" />
                <Tab label="Designs" />
            </Tabs>
            <div>
                {activeTab === 0 && <div><TemplateCreator /></div>}
                {activeTab === 1 && <div><ApproveTemplates /></div>}
                {activeTab === 2 && <div><UserConsole /></div>}
                {activeTab === 3 && <div><Designs/></div>}
            </div>
        </div>
    );
}
