import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import ZipUpload from "./ZipUpload";
import { UserConsole } from "./UserConsole";
import TemplateCreator from "./TemplateCreator";
import TemplateEditor from "./TemplateEditor";
import MyTemplates from "./MyTemplates";
import { Admin } from "./Admin";

export function MyRoutes() {
    return (
      <Router basename="/">
        <Routes>
          <Route path="/template/:templateId" element={<TemplateEditor />} />
          <Route path="/create-template" element={<TemplateCreator />} />
          <Route path="/template/upload/" element={<ZipUpload />} />
          <Route path="/" element={<MyTemplates />} />


          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/users" element={<UserConsole />} />

          <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}
