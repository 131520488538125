import { useEffect, useState } from "react"
import { req } from "./ServerUtils";
import { Button } from "@mui/material";

export function Designs({}) {
    const [designs, setDesigns] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        req('/admin/html_designs').then(data => setDesigns(data.designs || []));
    }, [])

    function loadMore() {
        req('/admin/html_designs?page=' + (page + 1)).then(data => {
            setDesigns([...designs, ...data.designs]);
            setPage(page + 1);
        });
    }

    console.log(designs)

    function preview(design) {
        const urls = design.metainfo?.png_urls;
        if (!urls || urls.length === 0) return '';
        const urlInfo = urls[0];
        if (urlInfo && urlInfo.bucket && urlInfo.key)
            return "https://" + urlInfo.bucket + '/' + urlInfo.key;
        return '';
    }
    
    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 20,
                padding: 20,
            }}>
                {designs.map((design, i) => (
                    <div key={i}>
                        <img src={preview(design)} alt="Design Preview" style={{ 
                            width: 100,
                            height: 200,
                            objectFit: 'cover',
                        }} />
                    </div>
                ))}
            </div>
            <div style={{
                padding: 10,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={loadMore}
                    >
                    Load More
                </Button>

            </div>
        </div>
    )
}