import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Alert, Box, Modal, TextareaAutosize, ButtonGroup, IconButton, CircularProgress, Select, MenuItem } from '@mui/material';
import { req, reqPoll } from './ServerUtils';
import ImagesUploader from './ImagesUploader';
import { useParams } from 'react-router-dom';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import InspirationUploader from './InspirationUploader';
import { SetDesigner, SetDesignerWidget } from './SetDesigner';


const TemplateCreator = () => {
  const [id, setId] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateInspiration, setTemplateInspiration] = useState('');
  const [designer, setDesigner] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    req('/user_info').then(uinfo => {
      setUserInfo(uinfo);
      if (!uinfo) {
        if (!uinfo.permissions.includes("admin")) {
          req('/sus', 'POST', {
            'reason': 'Unauthorized access attempt to /templates'
          });
        }
        window.location.href = '/login';
        return;
      }
      setLoading(false);
    }).catch(e=> {
      window.location = '/login';
    })
  }, []);

  const onSave = async (e) => {
    e.preventDefault();
    req('/admin/html_templates', 'POST', JSON.stringify({
        id,
        title: templateTitle,
        width: 300,
        height: 800,
        designer_email: designer,
    })).then(res => {
        console.log(`Template ID: ${res.id}`);
    }).catch(error => {
        console.log('Error creating template: ' + error.message);
    })

  };

  if (!userInfo || !userInfo.permissions.includes("admin")) {
    return (<div>Loading...</div>);
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Box component="form" onSubmit={(e) => e.preventDefault()}>
            <>
            <SetDesignerWidget designer={designer} setDesigner={setDesigner} />
            <TextField
            fullWidth
            label="Id"
            variant="outlined"
            margin="normal"
            value={id}
            onChange={(e) => setId(e.target.value)}
            />
            <InspirationUploader templateId={id} setTemplateInspiration={setTemplateInspiration} />
        </>
        <Select style={{marginTop: 20}} size="small" fullWidth value={templateTitle} onChange={(e) => setTemplateTitle(e.target.value)} sx={{ mb: 2 }}>
            {subjects.map((subject, index) => (
            <MenuItem key={index} value={subject}>{subject}</MenuItem>
            ))}
        </Select>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" color="primary" onClick={onSave} disabled={loading}>
              Submit
            </Button>
            {loading && <CircularProgress />}
          </Box>
        </Box>
        {error && (
          <Alert severity={error?.startsWith('Error') ? 'error' : 'success'} sx={{ mt: 3 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default TemplateCreator;

const subjects = [
    "Tips for a green garden",
    "Sourdough Starter Recipe",
    "Mastering Tantrums: Parenting Secrets",
    "Screen-Free Fun for Kids",
    "Raising Confident, Happy Children",
    "Time-Saving Hacks for Parents",
    "Parenting Trends to Watch 2025",
    "AI Tools Revolutionizing Classrooms",
    "How to Inspire Lifelong Learning",
    "Exam Prep: Myths vs. Reality",
    "Revolutionizing Education with AR",
    "Creative Study Hacks for Teens",
    "Zero-Budget Startup Success Hacks",
    "From Idea to Market Dominance",
    "Secrets to Scale Without Fear",
    "Lessons Learned From Failure",
    "Future-Proofing Your Business Vision",
    "Content That Sells Itself",
    "Influence Through Storytelling Tactics",
    "Next-Gen Strategies for Marketers",
    "Viral Campaigns Demystified",
    "Marketing on a Shoestring Budget",
    "Unlocking Donor Psychology Insights",
    "Fundraising Events That Stand Out",
    "Small Actions, Big Donations",
    "Pitch Perfect for Fundraisers",
    "Crafting Your Fundraising Masterplan",
    "Remote Work Productivity Hacks",
    "Staying Social While Remote",
    "Digital Nomad Life Simplified",
    "Balancing Home and Work Virtually",
    "Best Tools for Virtual Teams",
    "Crafting Your Digital Identity",
    "Creative Freedom in a Digital Era",
    "Time Management for Content Creators",
    "Turning Followers into Fans",
    "Monetizing Passion Without Burnout",
    "AI Assistants: Life Simplified",
    "Automating Mundane Daily Tasks",
    "AI Tools for Smarter Shopping",
    "Everyday AI Hacks You’ll Love",
    "Making AI Work for You",
    "Lab-Grown Meat: The Future",
    "Food Tech Innovations to Watch",
    "Sustainable Eats Made Simple",
    "Smart Gadgets for Better Cooking",
    "The Rise of Personalized Diets"
  ]
  