import React from 'react';
import { Editor } from '@monaco-editor/react';

const HtmlEditor = ({ code, setCode }) => {
  const screenHeight = window.innerHeight;
  return (
    <Editor
      height={(screenHeight - 128) + "px"}
      defaultLanguage="html"
      value={code}
      setCode={setCode}
      onChange={(value) => setCode(value || '')}
      options={{
        automaticLayout: true,
        minimap: { enabled: false },
      }}
    />
  );
};

export default HtmlEditor;
