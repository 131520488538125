import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Alert, Box, Modal, TextareaAutosize, ButtonGroup, IconButton, CircularProgress, Select, MenuItem, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { req, reqPoll } from './ServerUtils';
import ImagesUploader from './ImagesUploader';
import { useParams } from 'react-router-dom';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import InspirationUploader from './InspirationUploader';
import { SetDesigner, SetDesignerWidget } from './SetDesigner';
import HtmlEditor from './HtmlEditor';

const svgIcon = `<svg viewBox="0 0 256 256" height="256" width="256"><circle r="128" cx="128" cy="128" fill="limegreen" /></svg>`
const imgSrc = 'https://placehold.co/600x400/EEE/31343C';

const presets = [{
  name: 'Portrait',
  width: 600,
  height: 1200,
  icon: <CropPortraitIcon />
}, {
  name: 'Landscape',
  width: 800,
  height: 1200,
  icon: <CropLandscapeIcon />
}]

const TemplateEditor = () => {
  const {templateId} = useParams();
  const [id, setId] = useState(templateId);
  const [htmlContent, setHtmlContent] = useState(null);
  const [iframeHtml, setIframeHtml] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    sectionHeader: {
      title: 'Catchy Infographic header',
      subtitle: 'Subtitle to go with the header',
      body: 'This is an insightful, detailed body of the section that is interesting, fun and informative.',
    },
    blocks: Array(10).fill(0).map((_, index) => ({
      title: `Title of ${index + 1} block`,
      subtitle: `Subtitle of ${index + 1} block`,
      body: `This is an insightful, detailed body of the ${index + 1} block that is interesting, fun and informative.`
    })),
    footer: {
      title: 'Footer Title',
      subtitle: 'Footer Subtitle',
      body: 'Footer Body'
    }
  });
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateInspiration, setTemplateInspiration] = useState('');
  const [designer, setDesigner] = useState(null);

  const [loading, setLoading] = useState(false);
  const [templateImages, setTemplateImages] = useState([]);
  const [preset, setPreset] = useState(presets[0]);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setLoading(true);
    req('/user_info').then(uinfo => {
      setUserInfo(uinfo);
      if (!uinfo) {
        if (!templateId && !uinfo.permissions.includes("admin")) {
          req('/sus', 'POST', {
            'reason': 'Unauthorized access attempt to /templates'
          });
        }
        if (templateId && !uinfo.permissions.includes("designer")) {
          req('/sus', 'POST', {
            'reason': 'Unauthorized access attempt to /templates'
          });
        }
        window.location.href = '/login';
        return;
      }
      console.log(uinfo)
      
    
      req(`/designer/html_template?id=${templateId}`)
        .then((res) => {
          setLoading(false);
          if (res.error) {
            console.error(res.error);
          } else {
            
            setTemplateImages(res.images);
            setHtmlContent(res.pages[0]?.html);
            setTemplateTitle(res?.title || "")
            setTemplateInspiration(res.inspiration);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }).catch(e=> {
      window.location = '/login';
    })
  }, []);

  useEffect(() => {

  }, [preset])

  const handleModalChange = (section, field, value, index = null) => {
    setModalData((prev) => {
      const updated = { ...prev };
      if (index !== null) {
        updated[section][index][field] = value;
      } else {
        updated[section][field] = value;
      }
      return updated;
    });
  };

  const generateSample = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);

    req('/designer/html_templates', 'PUT', JSON.stringify({
        id,
        title: templateTitle,
        pages: [{
          html: htmlContent,
          config: null
        }],
        width: 300,
        height: 800
    })).then(res => {
        console.log(`Template ID: ${res.id} saved`);
        return reqPoll('/generate-sample', 'POST', JSON.stringify({
            template_id: id,
            subject: templateTitle
        }))
    }).then(res => {
        setLoading(false);
        window.open(res.url, '_blank');
        // window.open('/design/' + res.design_id, '_blank');
    }).catch(error => {
        setLoading(false);
        setError('Error generating sample: ' + error.message);
    });
  };

  const substituteDataIntoHTML = (rawHTML, data) => {
    setError(null)
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawHTML, 'text/html');

    function sub(html, data, pos=1) {
      return html
        .replace('{title}', data.title)
        .replace('{subtitle}', data.subtitle)
        .replace('{body}', data.body)
        .replace('{icon}', svgIcon)
        .replace("{image}", imgSrc)
        .replace('{pos}', pos)
    }
  
    // Substitute data into header
    const header = doc.querySelector('.mmb-header');
    if (header) {
      header.innerHTML = sub(header.innerHTML, data.sectionHeader);
    }

    const blocksContainer = doc.querySelector('.mmb-blocks');
    if (!blocksContainer) {
      setError("Error: Blocks container with class .mmb-blocks not found");
    }
  
    // Substitute data into blocks
    const blocks = doc.querySelectorAll('.mmb-blocks .mmb-block');
    blocks.forEach((block, index) => {
      if (data.blocks[index]) {
        block.innerHTML = sub(block.innerHTML, data.blocks[index], index + 1);
      }
    });
  
    // Substitute data into footer
    const footer = doc.querySelector('.mmb-footer');
    if (footer) {
      footer.innerHTML = sub(footer.innerHTML, data.footer);
    }

    const body = doc.body.outerHTML;
    const head = doc.head.outerHTML;

    return `<!DOCTYPE html><html>${head}${body}</html>`;
  };

  useEffect(() => {
    if (!htmlContent) {
      return;
    }
    setIframeHtml(substituteDataIntoHTML(htmlContent, modalData));
  }, [htmlContent]);

  function replaceImageUrls() {
    setHtmlContent(htmlContent.replace(/images\//g, `https://cdn.makemybrand.ai/template-images/${id}/`));
  }

  const [activeTab, setActiveTab] = useState('preview');

return (
  <div style={{ flex: 1, textAlign: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
      <ToggleButtonGroup
        size="small"
        value={activeTab}
        exclusive
        onChange={(e, v) => setActiveTab(v)}
        fullWidth
        sx={{  }}
      >
        <ToggleButton color="secondary" value="html" sx={{ textTransform: 'none' }}>
        HTML
        </ToggleButton>
        <ToggleButton color="secondary" value="preview" sx={{ textTransform: 'none' }}>
        Preview
        </ToggleButton>
        <ToggleButton color="secondary" value="data" sx={{ textTransform: 'none' }}>
          Data
        </ToggleButton>
        <ToggleButton color="secondary" value="inspiration" sx={{ textTransform: 'none' }}>
        Inspiration
        </ToggleButton>
      </ToggleButtonGroup>
      
    
    </div>

    <>
      {activeTab === 'html' && <Html htmlContent={htmlContent} setHtmlContent={setHtmlContent} id={id} templateImages={templateImages} setTemplateImages={setTemplateImages} replaceImageUrls={replaceImageUrls} generateSample={generateSample} loading={loading} error={error} />}
      {activeTab === 'preview' && (
        <div style={{
          textAlign: "center",
          width: '100%',
        }}>
          <div style={{ 
            display: 'flex', 
            gap: '8px', 
            textAlign: "center",
            justifyContent: 'center',
          }}>
            {presets.map((preset, index) => (
              <IconButton
                key={index}
                onClick={() => setPreset(preset)}
                variant="outlined"
              >
                {preset.icon}
              </IconButton>
            ))}
          </div>
          <iframe
            title="HTML Preview"
            style={{ 
              width: preset.width,
              height: preset.height,
              border: 'none' 
            }}
            srcDoc={iframeHtml}
          />
        </div>
      )}
      {activeTab === 'data' && <div><Data modalData={modalData} setModalData={setModalData} handleModalChange={handleModalChange}
      /></div>}
      {activeTab === 'inspiration' && <div>
        <img src={templateInspiration} alt="Inspiration" style={{  }} />
        </div>}
    </>
  </div>
);
};

function Html({htmlContent, setHtmlContent, id, templateImages, setTemplateImages, replaceImageUrls, generateSample, loading, error}) {
  return (
    <div style={{ 
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'start',  
      alignItems: 'stretch',
      height: "100%"
      
    }}>
      <div style={{ 

      }}>
        <HtmlEditor 
          code={htmlContent}
          setCode={setHtmlContent}
          onChange={(value) => setHtmlContent(value)}
          
        />
      </div>
      <ImagesUploader templateId={id} replaceImageUrls={replaceImageUrls} templateImages={templateImages} setTemplateImages={setTemplateImages} />
      
      <div>
        <Box sx={{ display: 'flex' }}>
          <Button variant="contained" color="secondary" onClick={generateSample} disabled={loading} fullWidth>Generate Sample</Button>
          {loading && <CircularProgress />}
        </Box>
        {error && (
        <Alert severity={error?.startsWith('Error') ? 'error' : 'success'} sx={{ mt: 3 }}>
          {error}
        </Alert>
      )}
      </div>
      
  </div>
  )
}

function Data({modalData, setShowModal, handleModalChange}) {
  return <Box style={{
    padding: 10,
    maxWidth: 600,
    textAlign: 'center',
    margin: 'auto'
  }}>
      <Typography variant="h6">Section Header</Typography>
      <TextareaAutosize
        minRows={2}
        style={{ width: '100%' }}
        value={modalData.sectionHeader.title}
        onChange={(e) => handleModalChange('sectionHeader', 'title', e.target.value)}
        placeholder="Title"
      />
      <TextareaAutosize
        minRows={2}
        style={{ width: '100%', marginTop: '10px' }}
        value={modalData.sectionHeader.body}
        onChange={(e) => handleModalChange('sectionHeader', 'body', e.target.value)}
        placeholder="Body"
      />
    
      <Typography variant="h6">Blocks</Typography>
      {modalData.blocks.map((block, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <TextareaAutosize
            minRows={2}
            style={{ width: '100%' }}
            value={block.title}
            onChange={(e) => handleModalChange('blocks', 'title', e.target.value, index)}
            placeholder={`Block ${index + 1} Title`}
          />
          <TextareaAutosize
            minRows={2}
            style={{ width: '100%', marginTop: '10px' }}
            value={block.body}
            onChange={(e) => handleModalChange('blocks', 'body', e.target.value, index)}
            placeholder={`Block ${index + 1} Body`}
          />
        </Box>
      ))}
   
      <Typography variant="h6">Footer</Typography>
      <TextareaAutosize
        minRows={2}
        style={{ width: '100%' }}
        value={modalData.footer.title}
        onChange={(e) => handleModalChange('footer', 'title', e.target.value)}
        placeholder="Footer Title"
      />
      <TextareaAutosize
        minRows={2}
        style={{ width: '100%', marginTop: '10px' }}
        value={modalData.footer.body}
        onChange={(e) => handleModalChange('footer', 'body', e.target.value)}
        placeholder="Footer Body"
      />
   
  </Box>
}
export default TemplateEditor;

